import React, { useState, useEffect } from 'react';
import { Route, Routes, NavLink, useLocation } from 'react-router-dom';
import { FaUtensils, FaLightbulb, FaBook, FaUser, FaHome } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
import SuggestionsPage from './pages/SuggestionsPage';
import StudyPage from './pages/StudyPage';
import StudyGuidePage from './pages/StudyGuidePage';
import AdminPage from './pages/AdminPage';
import GroupSetupWizard from './pages/GroupSetupWizard';
import EditorPage from './pages/EditorPage';
import EditSuggestionsPage from './pages/EditSuggestions';
import EditGroupDetailsPage from './pages/EditGroupDetailsPage';
import EditStudyGuidesPage from './pages/EditStudyGuidesPage';
import ProfilePage from './pages/ProfilePage';
import GroupAboutPage from './pages/GroupAboutPage';
import ManageMembersPage from './pages/ManageMembersPage';
import AcceptInvitationPage from './pages/AcceptInvitationPage.js';
import './App.css';
import { useGroup } from './components/GroupContext';

function App() {
    const { isAuthenticated } = useAuth0();
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode ? JSON.parse(savedMode) : false;
    });

    const { groupId } = useGroup();
    const location = useLocation();

    useEffect(() => {
        document.body.classList.toggle('dark-mode', darkMode);
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);

    useEffect(() => {
        if (window.plausible) {
            window.plausible('pageview', { props: { path: location.pathname } });
        }
    }, [location]);

    const toggleTheme = () => setDarkMode(prevMode => !prevMode);

    return (
        <div className="App">
            <header className="App-header">
                <h1>GroupHub</h1>
            </header>
            <main>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/group/:groupId/about" element={<GroupAboutPage />} />
                    {isAuthenticated && (
                        <>
                            <Route path="/group/:groupId/signup" element={<SignUpPage />} />
                            <Route path="/group/:groupId/edit-suggestions" element={<EditSuggestionsPage />} />
                            <Route path="/group/:groupId/study" element={<StudyPage />} />
                            <Route path="/group/:groupId/study/:guideId" element={<StudyGuidePage />} />
                            <Route path="/group/:groupId/suggestions" element={<SuggestionsPage />} />
                            <Route path="/group/:groupId/admin" element={<AdminPage />} />
                            <Route path="/group/:groupId/edit-study-guides" element={<EditStudyGuidesPage />} />
                            <Route path="/group/:groupId/editor" element={<EditorPage />} />
                            <Route path="/group/:groupId/editor/:id" element={<EditorPage />} />
                            <Route path="/group/:groupId/edit-group-details" element={<EditGroupDetailsPage />} />
                            <Route path="/group/:groupId/manage-members" element={<ManageMembersPage />} />
                            <Route path="/profile" element={<ProfilePage darkMode={darkMode} toggleTheme={toggleTheme} />} />
                            <Route path="/admin/setup-group" element={<GroupSetupWizard />} />
                            <Route path="/invite/:token" element={<AcceptInvitationPage />} />
                        </>
                    )}
                </Routes>
            </main>
            {isAuthenticated && (
                <nav className="bottom-nav">
                    <ul>
                        <li>
                            <NavLink to="/" aria-label="Home" className={({ isActive }) => isActive ? 'active' : ''}>
                                <FaHome />
                            </NavLink>
                        </li>
                        {groupId && (
                            <>
                                <li>
                                    <NavLink to={`/group/${groupId}/signup`} aria-label="Sign Up" className={({ isActive }) => isActive ? 'active' : ''}>
                                        <FaUtensils />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/group/${groupId}/suggestions`} aria-label="Suggestions" className={({ isActive }) => isActive ? 'active' : ''}>
                                        <FaLightbulb />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/group/${groupId}/study`} aria-label="Study Guides" className={({ isActive }) => isActive ? 'active' : ''}>
                                        <FaBook />
                                    </NavLink>
                                </li>
                            </>
                        )}
                        <li>
                            <NavLink to="/profile" aria-label="Profile" className={({ isActive }) => isActive ? 'active' : ''}>
                                <FaUser />
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
}

export default App;
