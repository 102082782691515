import React, { useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGroup } from '../components/GroupContext';
import '../css/GroupSetupWizard.css';

const GroupSetupWizard = () => {
    const { user } = useAuth0(); // Get the user from Auth0 context
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const navigate = useNavigate();
    const params = useParams(); // Get params from URL
    const { groupId: contextGroupId } = useGroup(); // Get groupId from context

    // Use groupId from params if available, otherwise use contextGroupId
    const groupId = params.groupId || contextGroupId;
    
    console.log('Current groupId:', groupId); // Debugging

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/groups', {
                name,
                description,
                isPublic,
                userId: user.sub, // Add user ID to the request body
            });
            alert(response.data.message);
            const groupId = response.data.group._id;
            navigate(`/group/${groupId}/about`);
        } catch (error) {
            console.error('Error creating group:', error.response ? error.response.data : error.message);
            alert('Failed to create group. Please try again.');
        }
    };

    return (
        <div className="group-setup-container">
            <h1>Setup New Group</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Group Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="isPublic">Public Group</label>
                    <input
                        type="checkbox"
                        id="isPublic"
                        checked={isPublic}
                        onChange={() => setIsPublic(!isPublic)}
                    />
                </div>
                <button type="submit">Create Group</button>
            </form>
        </div>
    );
};

export default GroupSetupWizard;
