import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useParams, Link } from 'react-router-dom';
import '../css/StudyGuidePage.css';

const StudyGuidePage = () => {
    const { groupId, guideId } = useParams();
    const [studyGuide, setStudyGuide] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStudyGuide = async () => {
            try {
                const response = await axios.get(`/api/study-guides/${groupId}/${guideId}`);
                setStudyGuide(response.data);
                setError(null); // Clear any previous error
            } catch (error) {
                console.error('Error fetching study guide:', error);
                setError('Failed to load study guide. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (groupId && guideId) {
            fetchStudyGuide();
        }
    }, [groupId, guideId]);

    const components = {
        a: ({ href, children }) => {
            const youtubeMatch = href.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/) ||
                                 href.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
            if (youtubeMatch) {
                const videoId = youtubeMatch[1];
                return (
                    <div className="video-container">
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                );
            }
            return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>; // Open links in a new tab
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!studyGuide) {
        return <div>No study guide found.</div>; // Fallback message if no data
    }

    return (
        <div className="study-guide-container">
            <Link to={`/group/${groupId}/study`} className="back-button">Back to List</Link>
            <h2>{studyGuide.title || 'Untitled Study Guide'}</h2> {/* Fallback title */}
            <ReactMarkdown components={components}>{studyGuide.content || 'No content available.'}</ReactMarkdown> {/* Fallback content */}
        </div>
    );
};

export default StudyGuidePage;
