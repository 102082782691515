// src/components/withRole.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const withRole = (Component, requiredRole) => {
  return (props) => {
    const { isAuthenticated, user, isLoading, error } = useAuth0();

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (error) {
      console.error('Auth0 Error:', error);
      return <div>Authentication error. Please try again later.</div>;
    }

    const userRoles = user && user['https://groupdev.petieclark.com/roles'];
    const hasRequiredRole = userRoles && userRoles.includes(requiredRole);

    if (!isAuthenticated || !hasRequiredRole) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };
};

export default withRole;
