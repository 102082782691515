import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const AcceptInvitationPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect, user, isLoading } = useAuth0();

    useEffect(() => {
        const acceptInvitation = async (auth0Id) => {
            try {
                // Send the auth0Id to the backend to add the user to the group
                await axios.post(`/api/invite/${token}`, { auth0Id });
                alert('You have been successfully added to the group!');
                navigate('/'); // Redirect to the homepage or group page after successful signup
            } catch (error) {
                console.error('Error accepting invitation:', error);
                alert('Failed to accept invitation. Please try again or contact the group admin.');
            }
        };

        if (isLoading) return; // Wait for Auth0 to load authentication status

        // If user is authenticated, proceed with accepting the invitation
        if (isAuthenticated && user) {
            const auth0Id = user.sub; // Retrieve the user's auth0Id from Auth0 user object
            acceptInvitation(auth0Id);
        } else if (!isAuthenticated) {
            // Redirect to Auth0 login if the user is not authenticated
            loginWithRedirect({
                appState: { returnTo: window.location.pathname }, // Return to this page after login
            });
        }
    }, [isAuthenticated, user, token, navigate, loginWithRedirect, isLoading]);

    return <div>Accepting invitation...</div>;
};

export default AcceptInvitationPage;
