import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { useGroup } from '../components/GroupContext';

const EditSuggestionsPage = () => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [selectedTab, setSelectedTab] = useState('write');
    const { groupId } = useParams();
    const navigate = useNavigate();
    const { setGroupId } = useGroup();

    useEffect(() => {
        if (groupId) {
            setGroupId(groupId); // Set the groupId in context

            const fetchSuggestion = async () => {
                try {
                    const response = await axios.get(`/api/suggestions/${groupId}`);
                    const suggestion = response.data;
                    setTitle(suggestion.title || '');
                    setText(suggestion.text || '');
                } catch (error) {
                    console.error('Error fetching suggestion:', error);
                }
            };

            fetchSuggestion();
        }
    }, [groupId, setGroupId]);

    const handleSave = async () => {
        if (!title.trim() || !text.trim()) {
            alert('Title and text cannot be empty.');
            return;
        }

        try {
            console.log('Saving suggestion for groupId:', groupId); // Debugging
            await axios.put(`/api/suggestions/${groupId}`, { title, text });
            alert('Suggestions updated successfully!');
            navigate(`/group/${groupId}/admin`); // Redirect to admin page
        } catch (error) {
            console.error('Error updating suggestions:', error);
            alert('Failed to update suggestions. Please try again.');
        }
    };

    const handleBackToAdmin = () => {
        navigate(`/group/${groupId}/admin`); // Navigate back to admin page
    };

    return (
        <div>
            <h2>Edit Suggestions</h2>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
            />
            <ReactMde
                value={text}
                onChange={setText}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
            />
            <div>
                <button onClick={handleSave}>Save Suggestions</button>
                <button onClick={handleBackToAdmin}>Back to Admin Page</button>
            </div>
        </div>
    );
};

export default EditSuggestionsPage;
