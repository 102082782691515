import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const SuggestionsPage = () => {
    const { groupId } = useParams();
    const [suggestions, setSuggestions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSuggestions = async () => {
            try {
                const response = await axios.get(`/api/suggestions/${groupId}`);
                setSuggestions(response.data);
                setError(null); // Clear any previous error
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setError('Failed to load suggestions. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (groupId) {
            fetchSuggestions();
        }
    }, [groupId]);

    if (loading) {
        return <div>Loading suggestions...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!suggestions || (!suggestions.title && !suggestions.text)) {
        return <div>No suggestions available.</div>;
    }

    return (
        <div className="suggestions-container">
            <h2>{suggestions.title || 'Untitled Suggestions'}</h2> {/* Fallback title */}
            <ReactMarkdown>{suggestions.text || 'No content available.'}</ReactMarkdown> {/* Fallback content */}
        </div>
    );
};

export default SuggestionsPage;
