import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useGroup } from '../components/GroupContext';
import '../css/GroupAboutPage.css';

const GroupAboutPage = () => {
    const params = useParams();
    const { user, isAuthenticated } = useAuth0();
    const [group, setGroup] = useState(null);
    const [isMember, setIsMember] = useState(false);
    const { groupId: contextGroupId } = useGroup();
    const groupId = params.groupId || contextGroupId;

    useEffect(() => {
        const fetchGroupDetails = async () => {
            try {
                const response = await axios.get(`/api/groups/${groupId}`);
                console.log("Fetched Group Data:", response.data); // Debugging output
                setGroup(response.data);

                // Check if the user is a member of the group, only if `members` is an array
                if (isAuthenticated && Array.isArray(response.data.members)) {
                    const userIsMember = response.data.members.some(memberId => memberId === user.sub);
                    setIsMember(userIsMember);
                } else {
                    console.warn("Group members data is missing or not an array."); // Debugging output
                }
            } catch (error) {
                console.error('Error fetching group details:', error);
            }
        };

        fetchGroupDetails();
    }, [groupId, isAuthenticated, user]);

    const joinGroup = async () => {
        if (!isAuthenticated) {
            alert('Please log in to join the group.');
            return;
        }

        try {
            await axios.put(`/api/groups/${groupId}/addMember`, { auth0Id: user.sub });
            alert('You have successfully joined the group!');
            setIsMember(true);
        } catch (error) {
            console.error('Error joining group:', error);
            alert('Failed to join the group. Please try again.');
        }
    };

    const leaveGroup = async () => {
        if (!isAuthenticated) {
            alert('Please log in to leave the group.');
            return;
        }

        if (group?.admins?.includes(user.sub)) {
            alert('You are a group leader, please have another leader move you to member before leaving the group.');
            return;
        }

        try {
            await axios.put(`/api/groups/${groupId}/removeMember`, { auth0Id: user.sub });
            alert('You have successfully left the group.');
            setIsMember(false);
        } catch (error) {
            console.error('Error leaving group:', error);
            alert('Failed to leave the group. Please try again.');
        }
    };

    if (!group) {
        return <p>Loading...</p>;
    }

    return (
        <div className="group-about-container">
            <h2 className="group-name">{group.name}</h2>
            <div className="group-description">
                <ReactMarkdown>{group.description}</ReactMarkdown>
            </div>

            {!isMember && (
                <button onClick={joinGroup} className="join-group-button">Join This Group</button>
            )}

            {isMember && (
                <>
                    <button onClick={leaveGroup} className="leave-group-button">Leave This Group</button>
                    <nav className="group-nav">
                        <Link to={`/group/${groupId}/signup`}>Sign-Up</Link>
                        <Link to={`/group/${groupId}/suggestions`}>Suggestions</Link>
                        <Link to={`/group/${groupId}/study`}>Study Guides</Link>
                    </nav>
                </>
            )}
        </div>
    );
};

export default GroupAboutPage;
