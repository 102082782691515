import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/ManageMembersPage.css';

const ManageMembersPage = () => {
    const { groupId } = useParams();
    const [members, setMembers] = useState([]);
    const navigate = useNavigate();
    const [selectedMember, setSelectedMember] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const handleBackToAdmin = () => {
        navigate(`/group/${groupId}/admin`); // Navigate back to admin page
    };
    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(`/api/groups/${groupId}/members`);
                setMembers(response.data || []);
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };
        fetchMembers();
    }, [groupId]);

    const handleSearch = async () => {
        if (!searchInput) return;
        try {
            const response = await axios.get(`/api/users?search=${searchInput}`);
            setUserOptions(response.data || []);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleAddMember = async () => {
        if (selectedUser) {
            try {
                await axios.put(`/api/groups/${groupId}/addMember`, { auth0Id: selectedUser.auth0Id });
                setMembers([...members, selectedUser]);
                setSelectedUser(null);
                alert('Member added successfully!');
            } catch (error) {
                console.error('Error adding member:', error);
                alert('Failed to add member. Please try again.');
            }
        }
    };

    const generateInviteLink = () => {
        // Logic to generate invitation link
        const inviteToken = 'example-token';
        return `${window.location.origin}/invite/${inviteToken}`;
    };

    return (
        <div className="manage-members-container">
            <h1>Manage Members</h1>

            <div className="add-member-section">
                <h2>Add New Member</h2>
                <input
                    type="text"
                    placeholder="Search for users..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <button className="primary" onClick={handleSearch}>Search</button>

                <ul>
                    {userOptions.map(user => (
                        <li key={user._id} onClick={() => setSelectedUser(user)}>
                            {user.firstName} {user.lastName}
                        </li>
                    ))}
                </ul>

                <button className="primary" onClick={handleAddMember} disabled={!selectedUser}>Add Member</button>
            </div>

            <div className="invite-section">
                <h2>Invite a User</h2>
                <button className="primary" onClick={generateInviteLink}>Generate Invitation Link</button>
                <input type="text" readOnly value={generateInviteLink()} />
                <button className="secondary" onClick={() => navigator.clipboard.writeText(generateInviteLink())}>Copy Link</button>
                <button onClick={handleBackToAdmin}>Back to Admin Page</button>
            </div>
        </div>
    );
};

export default ManageMembersPage;
