import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaUser } from 'react-icons/fa';
import '../css/ProfilePage.css';
import { useGroup } from '../components/GroupContext';
import { useParams } from 'react-router-dom';

const ProfilePage = ({ darkMode, toggleTheme }) => {
    const { loginWithRedirect, logout, isAuthenticated, user, error } = useAuth0();
    const params = useParams();
    const { groupId: contextGroupId } = useGroup();
    const groupId = params.groupId || contextGroupId;

    const hasRole = (role) => user && user['https://groupdev.petieclark.com/roles']?.includes(role);
    const isAdmin = isAuthenticated && hasRole('SmallGroupAdmin');

    console.log('Current groupId:', groupId); // Debugging
    console.log('isAuthenticated:', isAuthenticated);
    console.log('User:', user);

    if (error) {
        console.error('Auth0 Error:', error);
        return <div className="error-message">Authentication error. Please try again later.</div>;
    }

    return (
        <div className="profile-page">
            <div className="profile-header">
                {isAuthenticated ? (
                    <img src={user.picture || 'placeholder-profile.png'} alt="Profile" />
                ) : (
                    <FaUser size={100} color="var(--text-color)" />
                )}
                <h2>{isAuthenticated ? `Welcome, ${user.name}` : 'Please Log In'}</h2>
            </div>
            <div className="profile-menu">
                {isAuthenticated ? (
                    <button onClick={() => logout({ returnTo: window.location.origin })}>
                        Log Out
                    </button>
                ) : (
                    <button onClick={() => loginWithRedirect()}>Log In</button>
                )}
                <button onClick={toggleTheme}>
                    {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                </button>
                {isAdmin && groupId && (
                    <button onClick={() => window.location.href = `/group/${groupId}/admin`}>
                        Admin Page
                    </button>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;
