import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import '../css/EditStudyGuidesPage.css';

const EditStudyGuidesPage = () => {
    const [studyGuides, setStudyGuides] = useState([]);
    const { groupId } = useParams();

    useEffect(() => {
        if (groupId) {
            fetchStudyGuides();
        }
    }, [groupId]);

    const fetchStudyGuides = async () => {
        try {
            const response = await axios.get(`/api/study-guides/${groupId}`);
            if (Array.isArray(response.data)) {
                setStudyGuides(response.data);
            } else {
                console.error('Unexpected response format:', response.data);
                setStudyGuides([]); // Reset to empty array if response is not an array
            }
        } catch (error) {
            console.error('Error fetching study guides:', error);
            setStudyGuides([]); // Reset to empty array on error
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this study guide?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`/api/study-guides/${groupId}/${id}`);
            setStudyGuides(studyGuides.filter(guide => guide._id !== id));
            alert('Study guide deleted successfully!');
        } catch (error) {
            console.error('Error deleting study guide:', error);
            alert('Failed to delete study guide. Please try again.');
        }
    };

    const toggleVisibility = async (id) => {
        const updatedGuides = studyGuides.map(guide => {
            if (guide._id === id) {
                return { ...guide, visible: !guide.visible };
            }
            return guide;
        });
        setStudyGuides(updatedGuides);

        try {
            const guide = updatedGuides.find(guide => guide._id === id);
            await axios.put(`/api/study-guides/${groupId}/${id}`, { visible: guide.visible });
            alert('Visibility updated successfully!');
        } catch (error) {
            console.error('Error updating visibility:', error);
            alert('Failed to update visibility. Reverting changes.');
            
            // Revert UI update on error
            setStudyGuides(studyGuides);
        }
    };

    return (
        <div className="edit-study-guides-container">
            <h2>Edit Study Guides</h2>
            <ul>
                {studyGuides.length > 0 ? (
                    studyGuides.map((guide) => (
                        <li key={guide._id}>
                            <Link to={`/admin/editor/${guide._id}`}>{guide.title}</Link>
                            <button onClick={() => handleDelete(guide._id)}>Delete</button>
                            <button onClick={() => toggleVisibility(guide._id)}>
                                {guide.visible ? 'Hide' : 'Show'}
                            </button>
                        </li>
                    ))
                ) : (
                    <p>No study guides available.</p>
                )}
            </ul>
            <Link to={`/group/${groupId}/admin`} className="back-button">Back to Admin</Link>
        </div>
    );
};

export default EditStudyGuidesPage;
