import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import '../css/StudyPage.css';

const StudyPage = () => {
    const [studyGuides, setStudyGuides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { groupId } = useParams();

    useEffect(() => {
        const fetchStudyGuides = async () => {
            try {
                const response = await axios.get(`/api/study-guides/${groupId}`);
                if (Array.isArray(response.data)) {
                    setStudyGuides(response.data);
                } else {
                    console.error('Invalid study guides data:', response.data);
                    setError('Failed to load study guides.');
                }
            } catch (error) {
                console.error('Error fetching study guides:', error);
                setError('Failed to load study guides. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (groupId) {
            fetchStudyGuides();
        }
    }, [groupId]);

    if (loading) {
        return <div>Loading study guides...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    const visibleGuides = studyGuides.filter(guide => guide.visible);

    if (visibleGuides.length === 0) {
        return <div>No study guides available.</div>;
    }

    return (
        <div className="study-container">
            <h2>Study Guides</h2>
            <div className="study-content">
                {visibleGuides.map((guide) => (
                    <div key={guide._id} className="study-guide-item">
                        <Link to={`/group/${groupId}/study/${guide._id}`}>
                            {guide.title || 'Untitled Study Guide'}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StudyPage;
