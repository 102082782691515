import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import '../css/SignUpPage.css';

Modal.setAppElement('#root');

const SignUpPage = () => {
    const { isAuthenticated, user } = useAuth0();
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState('');
    const { groupId } = useParams();
    const [name, setName] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [patchNotesOpen, setPatchNotesOpen] = useState(false);

    useEffect(() => {
        if (groupId) {
            fetchItems();
        }
        const hasSeenPatchNotes = localStorage.getItem('hasSeenPatchNotes');
        if (!hasSeenPatchNotes) {
            setPatchNotesOpen(true);
            localStorage.setItem('hasSeenPatchNotes', 'true');
        }
    }, [groupId]);

    useEffect(() => {
        if (isAuthenticated && user) {
            setName(user.given_name || user.name.split(' ')[0]);
        }
    }, [isAuthenticated, user]);

    const fetchItems = async () => {
        try {
            const response = await axios.get(`/api/food-signup/${groupId}`);
            if (Array.isArray(response.data)) {
                setItems(response.data);
            } else {
                console.error('Expected an array but got:', response.data);
                setItems([]);
            }
        } catch (error) {
            console.error('Error fetching sign-up data:', error);
            setItems([]);
        }
    };

    const handleSignUp = async () => {
        if (!name.trim() || !newItem.trim()) {
            setModalMessage("Please enter both your name and what you're bringing.");
            setModalIsOpen(true);
            return;
        }

        const payload = {
            bringing: newItem,
            name: name,
            groupId: groupId
        };

        try {
            await axios.post('/api/food-signup', payload);
            setModalMessage("Thank you for signing up!");
            setModalIsOpen(true);
            fetchItems();
            setNewItem('');
            if (!isAuthenticated) setName('');
        } catch (error) {
            console.error('Error posting sign-up data:', error);
            setModalMessage("Something went wrong. Please try again.");
            setModalIsOpen(true);
        }
    };

    return (
        <div className="sign-up-container">
            <h2>Food Sign-Up</h2>
            {!isAuthenticated && (
                <input
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            )}
            <input
                type="text"
                placeholder="What are you bringing?"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
            />
            <button onClick={handleSignUp}>Sign Up</button>
            <h2>Who's Bringing What?</h2>
            <ul>
                {items.length > 0 ? (
                    items.map((item, index) => (
                        <li key={index}>
                            <span>{item.name} is bringing {item.bringing}</span>
                        </li>
                    ))
                ) : (
                    <p>No one has signed up yet.</p>
                )}
            </ul>

            {/* Modal for Sign-up Message */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '16px',
                        padding: '20px',
                        backgroundColor: 'var(--background-color)',
                        color: 'var(--text-color)',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                    }
                }}
            >
                <h3>{modalMessage}</h3>
                <button
                    onClick={() => setModalIsOpen(false)}
                    style={{
                        background: 'var(--button-background-color)',
                        border: 'none',
                        color: 'var(--button-text-color)',
                        padding: '10px 20px',
                        borderRadius: '12px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        marginTop: '10px'
                    }}
                    onMouseOver={(e) => e.target.style.background = 'var(--button-hover-color)'}
                    onMouseOut={(e) => e.target.style.background = 'var(--button-background-color)'}
                >
                    Close
                </button>
            </Modal>

            {/* Patch Notes Modal */}
            <Modal
                isOpen={patchNotesOpen}
                onRequestClose={() => setPatchNotesOpen(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '16px',
                        padding: '20px',
                        backgroundColor: 'var(--background-color)',
                        color: 'var(--text-color)',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                    }
                }}
            >
                <h3>What's New</h3>
                <p>We've made some updates to improve your experience!</p>
                <button
                    onClick={() => setPatchNotesOpen(false)}
                    style={{
                        background: 'var(--button-background-color)',
                        border: 'none',
                        color: 'var(--button-text-color)',
                        padding: '10px 20px',
                        borderRadius: '12px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        marginTop: '10px'
                    }}
                    onMouseOver={(e) => e.target.style.background = 'var(--button-hover-color)'}
                    onMouseOut={(e) => e.target.style.background = 'var(--button-background-color)'}
                >
                    Close
                </button>
            </Modal>
        </div>
    );
};

export default SignUpPage;
